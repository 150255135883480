import React from 'react'
import { Link } from 'gatsby'

import { Helmet } from 'react-helmet'

import projectStyles from '../style.module.css'
import styles from './windows-r-d-p.module.css'

const WindowsRDP = (props) => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>Windows RDP - Covert_Bear</title>
        <meta property="og:title" content="Windows RDP - Covert_Bear" />
      </Helmet>
      <div data-role="Header" className={styles['navbar-container']}>
        <div className={styles['navbar']}>
          <div className={styles['left-side']}>
            <Link to="/" className={styles['navlink']}>
              <img
                alt="image"
                src="/playground_assets/icon-white-200h.png"
                className={styles['image']}
              />
            </Link>
            <div data-type="BurgerMenu" className={styles['burger-menu']}>
              <svg viewBox="0 0 1024 1024" className={styles['icon']}>
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
            <div className={styles['links-container']}>
              <Link
                to="/"
                className={` ${styles['link']} ${projectStyles['anchor']} `}
              >
                Home
              </Link>
              <Link
                to="/pricing"
                className={` ${styles['link01']} ${projectStyles['anchor']} `}
              >
                Pricing
              </Link>
              <Link
                to="/v-p-n"
                className={` ${styles['link02']} ${projectStyles['anchor']} `}
              >
                VPN
              </Link>
              <a
                href="#windows_heading"
                className={` ${styles['link03']} ${projectStyles['anchor']} `}
              >
                Windows RDP
              </a>
              <Link
                to="/linux-r-d-p"
                className={` ${styles['link04']} ${projectStyles['anchor']} `}
              >
                Linux RDP
              </Link>
            </div>
          </div>
          <div className={styles['right-side']}>
            <Link
              to="/contact1"
              className={` ${styles['cta-btn']} ${projectStyles['anchor']} ${projectStyles['button']} `}
            >
              Contact Us
            </Link>
          </div>
          <div data-type="MobileMenu" className={styles['mobile-menu']}>
            <div className={styles['container1']}>
              <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                className={styles['image1']}
              />
              <div data-type="CloseMobileMenu" className={styles['close-menu']}>
                <svg viewBox="0 0 1024 1024" className={styles['icon2']}>
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className={styles['links-container1']}>
              <a
                href="#resources"
                className={` ${styles['link05']} ${projectStyles['anchor']} `}
              >
                Resources
              </a>
              <span
                className={` ${styles['link06']} ${projectStyles['anchor']} `}
              >
                Inspiration
              </span>
              <a
                href="#process"
                className={` ${styles['link07']} ${projectStyles['anchor']} `}
              >
                Process
              </a>
              <span
                className={` ${styles['link08']} ${projectStyles['anchor']} `}
              >
                Our story
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['services']}>
        <div className={styles['heading-container']}>
          <h1
            id="windows_heading"
            className={` ${styles['text']} ${projectStyles['section-heading']} `}
          >
            Covert Bear Windows RDP
          </h1>
          <span
            className={` ${styles['text01']} ${projectStyles['section-text']} `}
          >
            We&apos;ve got you covered
          </span>
          <div className={styles['container2']}>
            <span className={styles['text02']}>
              Page Under Construction. For detailed information please
            </span>
            <Link to="/contact1" className={styles['navlink1']}>
              Contact Us.
            </Link>
          </div>
        </div>
        <div className={styles['cards-container']}>
          <div className={styles['service-card']}>
            <h3
              className={` ${styles['text03']} ${projectStyles['card-heading']} `}
            >
              A Computer away from Home
            </h3>
            <img
              alt="image"
              src="/playground_assets/w10_desktop_smol-1200w.png"
              className={styles['image2']}
            />
            <span
              className={` ${styles['text04']} ${projectStyles['card-text']} `}
            >
              <span>
                • A Windows RDP (Remote Desktop Portal) will get you a Remote
                (meaning somewhere else in the World - you can choose where)
                Windows Computer.
              </span>
              <br></br>
              <span>
                • You will connect to this computer and control it
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text07']}>remotely</span>
              <span>
                {' '}
                (tired of hearing this word yet?).
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                • You will see its Desktop the same way you see your own, when
                you move your mouse you will move its mouse.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                ​• It will basically be the same as using a VPN, except the VPN
                is cheaper and faster.
              </span>
              <br></br>
              <span>​</span>
              <br></br>
              <span className={styles['text13']}>VPN vs RDP</span>
              <br></br>
              <span>
                • With the VPN you use your own Computer which is in front of
                you.
              </span>
              <br></br>
              <span>
                • The Network Packets your Computer sends out are routed through
                the VPN Server, this would only add on a few milliseconds of
                time for the packet to reach the Web Server at the end, and for
                it to send you a reply.
              </span>
              <br></br>
              <span>
                • A VPN will work fine regardless of what Internet Speed you
                have, whether it&apos;s 1 MB, 5 MB, or 1000 MB, the difference
                will be barely noticeable.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                • With a Remote Desktop (RD), you will stream the display of the
                RD to your own Computer. This means instead of just routing
                normal traffic to and from your Computer, you&apos;re constantly
                downloading a video stream.
              </span>
              <br></br>
              <span>
                • If your Internet Connection isn&apos;t strong enough, you
                might not even be able to use this Service.
              </span>
              <br></br>
              <span>
                •
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text21']}>On top of that</span>
              <span>
                , the RDP needs
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text23']}>processing power</span>
              <span>
                . Cheaper options no matter where you go will be very
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className={styles['text25']}>laggy</span>
              <span> depending on the hardware you get.</span>
              <br></br>
              <span></span>
              <br></br>
              <span></span>
              <span className={styles['text30']}>
                Covert Bear recommendation
              </span>
              <br></br>
              <span>• We recommend getting the VPN.</span>
              <br></br>
              <span>
                • If you still want an RD, then decide why. If you&apos;d like
                to run well known apps then Windows is the way to go.
              </span>
              <br></br>
              <span>
                • If you only want it to surf the web and keep some small files
                saved, then our Linux RDP would be better.
              </span>
            </span>
            <div className={styles['container3']}>
              <span className={projectStyles['card-text']}>
                • Check out our
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <Link
                to="/linux-r-d-p"
                className={` ${styles['navlink2']} ${projectStyles['card-text']} `}
              >
                Linux RDP.
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['section-separator']}></div>
      <div className={styles['footer-container']}>
        <div className={styles['footer']}>
          <div className={styles['copyright-container']}>
            <span
              className={` ${styles['link09']} ${projectStyles['anchor']} `}
            >
              <span>© Copyright, 2021</span>
              <br></br>
              <span>Covert Bear, All Rights Reserved</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WindowsRDP
